<script lang="ts" setup>
import { showErrorPage } from '~/utils/showErrorPage'

/**
 * This component is the page component responsible for rendering g routes.
 * It renders the content under the /content directory that matches the given
 * route.
 *
 * @see: https://content.nuxt.com/components/content-doc#slots
 */
definePageMeta({
  showNotificationBar: true,
  showFullFooter: true,
  // Setting this to true sets the main content pane to a blank slate without
  // container styles. This allows the g page to accommodate the content
  // that is flush to header, footer, or edges of the main pane.
  containsFlushContent: true,
})

const route = useRoute()
// Note: entry-specific front matter is specified via the /content entry.
// The canonical link depends on the route, rather than the entry, so we specify
// it here.
useHead({
  link: [
    {
      rel: 'canonical',
      href: `https://grove.co${route.path}`,
    },
  ],
})

// Contain the components that should always be full width, listed by contentTypeId or slug.
const FULL_WIDTH_COMPONENTS = [
  'productLaunchHero',
  'imageCtaHero',
  'carouselQuote',
]

const getSpanBindings = (contentTypeId: String) => {
  const renderFullWidth = FULL_WIDTH_COMPONENTS.includes(contentTypeId)
  return {
    small: renderFullWidth,
    medium: renderFullWidth,
    large: renderFullWidth,
  }
}
</script>

<template>
  <NuxtLayout>
    <ContentDoc :path="route.path">
      <template #default="{ doc }">
        <div class="GPage">
          <SkyPage class="GPage_Content">
            <SkyFullSpan
              v-for="entry in doc.widgets"
              :key="entry.id"
              v-bind="getSpanBindings(entry.contentTypeId)"
            >
              <ContentfulContent :entries="[entry]" />
            </SkyFullSpan>
          </SkyPage>
        </div>
      </template>
      <template #not-found>
        {{
          showErrorPage(`Corresponding document not found for ${route.path}`)
        }}
      </template>
    </ContentDoc>
  </NuxtLayout>
</template>

<style lang="scss">
.GPage {
  max-width: 100vw;
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin-bottom: var(--spacing-12x);

  &_Content {
    row-gap: 0;
  }
}
</style>
